<template>
    <v-sheet>
        <SearchSharePointGroup
            v-model="selectedGroup"
            rounded
            outlined
        />
        
        <div v-if="config.loading">
            <v-skeleton-loader class="mb-4" type="paragraph" v-for="n in 10" :key="n" />
        </div>    

        <div 
            class="text-h5 font-weight-light grey--text text--darken-2 mb-2 mt-5"
            v-else-if="!config.loading && Object.keys(spoGroup).length !== 0"    
        >
                <v-icon large left>mdi-account-account</v-icon>
                    Users
                <v-btn
                    class="float-right ml-2 white--text"
                    v-if="!editMode"
                    color="green lighten-1"
                    @click="config.addUserDlg = true"
                >
                    <v-icon class="small">mdi-plus</v-icon>
                    Add
                </v-btn>
                <v-btn 
                    class="float-right ml-2"
                    v-if="editMode"
                    color="success"
                    @click.stop="$refs.confirmDlg.show()"
                    :disabled="selectedUsers.length == 0"
                >
                    <v-icon class="small">mdi-check</v-icon>
                    Submit
                </v-btn>
                
                <v-btn
                    class="float-right white--text"
                    @click="editMode = !editMode"
                    :color="editMode ? 'red' : 'blue-grey'"
                    v-if="spoGroup.users && spoGroup.users.length != 0"
                > 
                    <span v-if="!editMode">
                        <v-icon class="small">mdi-pencil</v-icon>
                        Edit
                    </span>
                    
                    <v-icon v-if="editMode" class="small">mdi-cancel</v-icon>
                    <span v-if="editMode">
                        Cancel
                    </span>
                </v-btn>
                <div v-if="spoGroup.users && spoGroup.users.length == 0">
                    <v-sheet
                        v-show="spoUser"
                        class="pa-3 text-center text-h6 font-weight-light mt-3"
                        outlined
                        rounded
                    >
                        <v-icon large left color="success">mdi-party-popper</v-icon>
                        There are no users in the SharePoint Group.
                    </v-sheet>
                </div>
                <div v-else-if="!config.loading && spoGroup.users && spoGroup.users.length" class="mt-5">
                    <v-data-table
                        v-model="selectedUsers"
                        :headers="headers"
                        :items="spoGroup.users"
                        :show-select="editMode"
                        class="elevation-1"
                        item-key="uni"
                        @toggle-select-all="() => {selectedUsers = spoGroup.users}"
                    >
                        <template v-slot:item.title="{ item }">
                            {{ item.title }}
                        </template>
                        <template v-slot:item.uni="{ item }">
                            {{ item.uni }}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{ item.email }}
                        </template>
                    </v-data-table>
                </div>
            </div>
        <v-dialog
            v-model="config.addUserDlg"
            max-width="350"
            persistent
        >
            <v-card class="rounded-0">
                <v-card-title>
                    Add Member
                </v-card-title>
                <v-card-subtitle v-if="selectedGroup">
                    {{ selectedGroup.name }}
                </v-card-subtitle>
                <v-card-text>
                    <SearchPeople
                        v-model="addUser"
                        label="Search people"
                        users-only
                        ldap-populate
                        dense
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="cancelAddNewUser"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        :disabled="!addUser || config.loading"
                        :loading="config.loading"
                        @click="submitAddUserToSpoGroup"
                        text
                    >
                        Add User
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmDialog
            ref="confirmDlg"
            title="Are you sure you want to remove the user(s)?"
            confirm-button-text="Confirm"
            confirm-button-icon="mdi-check"
            @confirm="submitRemoveUsersFromGroup"
        />
    </v-sheet>
</template>

<script>
import { reactive, watch, ref, onMounted, computed } from '@vue/composition-api';
import SearchSharePointGroup from '@/components/ops/spo/SearchSharePointGroup';
import SearchPeople from '@/components/population/SearchPeople';
import ConfirmDialog from '@/components/common/ConfirmDialog';

export default {
    name: 'SpoGroup',
    components: {
        SearchSharePointGroup,
        SearchPeople,
        ConfirmDialog
    },
    setup(props, { root }) {

        const store = root.$store;
        const selectedGroup = ref(null);
        const editMode = ref(false);
        const selectedUsers = ref([]);
        const addUser = ref(null);
        const spoGroup = computed(() => store.getters['ops/getSpoGroup']);

        const headers = [{
            text: 'Name',
            key: 'title',
            value: 'title',
        }, {
            text: 'CU UNI',
            key: 'uni',
            value: 'uni'
        }, {
            text: 'Email',
            key: 'email',
            value: 'email'
        }];

        const config = reactive({
            loading: false,
            addUserDlg: false
        });

        const reset = () => {
            editMode.value = false;
            selectedUsers.value = [];
            config.loading = false;
        }

        const submitRemoveUsersFromGroup = async () => {
            config.loading = true;
            let res = await store.dispatch('ops/removeUsersFromSpoGroup', {
                users: selectedUsers.value,
                group: selectedGroup.value.name
            });

            if (res.data.success) {
                await store.dispatch('ops/getSharePointGroups');
                await store.dispatch('ops/setSpoGroup', selectedGroup.value.name);
                
                reset();
            }

            config.loading = false;

        }
        
        
        const cancelAddNewUser = () => {
            selectedUsers.value = [];
            addUser.value = null;
            config.addUserDlg = false;
        
        }

        const submitAddUserToSpoGroup = async () => {

            config.loading = true;
            
            const response = await store.dispatch('ops/addUserToSpoGroup', {
                user: addUser.value.cu_uni,
                group: spoGroup.value.name
            });

            if (response.data.success) {
                await store.dispatch('ops/getSharePointGroups');
                await store.dispatch('ops/setSpoGroup', selectedGroup.value.name)
            }
            
            config.addUserDlg = false;
            addUser.value = null;
            config.loading = false;
            return;
        }

        watch(selectedGroup, async (val) => {
            if (val) {
                config.loading = true;
                await store.dispatch('ops/setSpoGroup', val.name);
                config.loading = false;
            }
        });

        onMounted(async () => {
            await store.dispatch('ops/resetSpoGroup');
        });

        return {
            config,
            headers,
            selectedGroup,
            editMode,
            submitRemoveUsersFromGroup,
            selectedUsers,
            addUser,
            cancelAddNewUser,
            submitAddUserToSpoGroup,
            spoGroup
        }
    },
};
</script>