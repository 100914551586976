import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"800px","eager":""},model:{value:(_vm.showDlg),callback:function ($$v) {_vm.showDlg=$$v},expression:"showDlg"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Add "+_vm._s(_vm.capitalize(_vm.user))+" to SharePoint Group")])]),_c(VCardText,[_c(VAutocomplete,{attrs:{"items":_vm.sharePointGroups,"density":"compact","loading":_vm.isLoading,"cache-items":"","label":"SharePoint Groups","item-text":"name","return-object":""},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1),(_vm.selectedGroup)?_c(VList,[(_vm.selectedGroup.owner)?_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-account-tie ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.selectedGroup.owner))]),_c(VListItemSubtitle,[_vm._v("Owner")])],1)],1):_vm._e(),_c(VDivider,{attrs:{"inset":""}}),(_vm.selectedGroup.users)?_c(VListGroup,{attrs:{"prepend-icon":"mdi-account-group"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Users")])],1)]},proxy:true}],null,false,1440671018)},_vm._l((_vm.selectedGroup.users),function(u,idx){return _c(VListItem,{key:idx},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-account ")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(u.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(u.uni)}})],1)],1)}),1):_vm._e()],1):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.resetDlg}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v("Add")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }