<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-4">SharePoint Utility</div>
            <div v-if="loading">
                <v-skeleton-loader class="mb-4" type="paragraph" v-for="n in 10" :key="n" />
            </div>
            <SpoUser v-if="!loading && $route.path.includes('user')" />
            <SpoGroup v-if="!loading && $route.path.includes('group')" />
        </v-container>
    </v-main>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api';
import SpoUser from '@/components/ops/spo/SpoUser';
import SpoGroup from '@/components/ops/spo/SpoGroup';

export default {
    components: {
        SpoUser,
        SpoGroup
    },
    setup(props, { root }) {

        const store = root.$store;
        
        console.log(root.$route.path)
        
        const loading = ref(false);
        const user = computed(() => store.getters['ops/getSpoUser']);

        onMounted(async () => {
            loading.value = true;
            await store.dispatch('ops/resetSpoUser');
            await store.dispatch('ops/getSharePointGroups');
            loading.value = false;
        })
        return {
            user,
            loading,
        }
    },
};
</script>