<template>
    <v-dialog v-model="showDlg" persistent max-width="800px" eager>
        <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                outlined
            >
                Add {{ user }} to SharePoint Group
            </v-btn>
        </template> -->
       
        <v-card>
            <v-card-title>
                <span class="headline">Add {{ capitalize(user) }} to SharePoint Group</span>
            </v-card-title>
            <v-card-text>
                <v-autocomplete
                    v-model="selectedGroup"
                    :items="sharePointGroups"
                    density="compact"
                    :loading="isLoading"
                    cache-items
                    label="SharePoint Groups"
                    item-text="name"
                    return-object
                >
                </v-autocomplete>
            </v-card-text>
            <v-list v-if="selectedGroup">
                <v-list-item 
                    v-if="selectedGroup.owner"
                >
                    <v-list-item-icon>
                        <v-icon>
                            mdi-account-tie
                        </v-icon>
                    </v-list-item-icon>
                    
                    <v-list-item-content>
                        <v-list-item-title>{{ selectedGroup.owner }}</v-list-item-title>
                        <v-list-item-subtitle>Owner</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                
                <v-divider inset></v-divider>
                
                <v-list-group 
                    v-if="selectedGroup.users"
                    prepend-icon="mdi-account-group"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>Users</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        v-for="u, idx in selectedGroup.users"
                        :key="idx"
                    >
                         <v-list-item-icon>
                            <v-icon>
                                mdi-account
                            </v-icon>
                        </v-list-item-icon>
                         <v-list-item-content>
                            <v-list-item-title v-text="u.title"></v-list-item-title>
                            <v-list-item-subtitle v-text="u.uni"></v-list-item-subtitle>
                        </v-list-item-content>
                       
                    </v-list-item>

                    
                </v-list-group>
            </v-list>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="resetDlg">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :loading="isLoading"
                    @click="submit"
                >Add</v-btn>
            </v-card-actions>
        </v-card>
        
    </v-dialog>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api';
import stringHelper from '@/composables/common/string';

export default {
    name: "AddUserToSpoGroupDialog",
    components: {
    },
    setup(props, { root }) {

        const store = root.$store;
        const selectedGroup = ref(null);
        const isLoading = ref(false);
        const user = computed(() => store.getters['ops/getLookupUser']);
        const sharePointGroups = computed(() => store.getters['ops/getSharePointGroups'])
        const showDlg = ref(false);
        const { capitalize } = stringHelper();

        const submit = async () => {
            isLoading.value = true;
            const res = await store.dispatch('ops/addUserToSpoGroup', {
                user: user.value,
                group: selectedGroup.value.name
            })

            if (res.data.success) {
                await store.dispatch('ops/loadSpoUser', {user: user.value});
            }

            resetDlg();

            isLoading.value = false;

        }

        const show = () => {
            showDlg.value = true;
        }

        const resetDlg = () => {
            showDlg.value = false;
            selectedGroup.value = null;
            isLoading.value = false;
        }
        onMounted(async () => {
            isLoading.value = true;
            // await store.dispatch('ops/getSharePointGroups');
            isLoading.value = false;
        })

        const search = () => {
            return
        }
        return {
            user,
            isLoading,
            submit,
            sharePointGroups,
            showDlg,
            show,
            selectedGroup,
            resetDlg,
            search,
            capitalize
        }
    },
};
</script>