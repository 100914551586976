<template>
        <div>
            <SearchPeople
                v-model="foundEmployee"
                label="Search people"
                :show-loader="false"
                users-only
                ldap-populate
                dense
                rounded
                outlined
            />

            <div v-if="loading">
                <v-skeleton-loader class="mb-4" type="paragraph" v-for="n in 10" :key="n" />
            </div>

            <div 
                class="text-h5 font-weight-light grey--text text--darken-2 mb-2 mt-5"
                v-if="!loading && spoUser.groups"    
            >
                <v-icon large left>mdi-account-group</v-icon>
                    Groups
                <v-btn
                    class="float-right ml-2 white--text"
                    v-if="!editMode"
                    color="green lighten-1"
                    @click="$refs.addUserToSpoGroupDlg.show()"
                >
                    <v-icon class="small">mdi-plus</v-icon>
                    Add
                </v-btn>
                <v-btn 
                    class="float-right ml-2"
                    v-if="editMode"
                    color="success"
                    @click.stop="$refs.confirmDlg.show()"
                    :disabled="selectedGroups.length == 0"
                >
                    <v-icon class="small">mdi-check</v-icon>
                    Remove
                </v-btn>
                
                <v-btn
                    class="float-right white--text"
                    @click="editMode = !editMode"
                    :color="editMode ? 'red' : 'blue-grey'"
                    v-if="spoUser.groups && spoUser.groups.length != 0"
                > 
                    <span v-if="!editMode">
                        <v-icon class="small">mdi-pencil</v-icon>
                        Edit
                    </span>
                    
                    <v-icon v-if="editMode" class="small">mdi-cancel</v-icon>
                    <span v-if="editMode">
                        Cancel
                    </span>
                </v-btn>
                <div v-if="spoUser.groups && spoUser.groups.length == 0">
                    <v-sheet
                        v-show="spoUser"
                        class="pa-3 text-center text-h6 font-weight-light mt-3"
                        outlined
                        rounded
                    >
                        <v-icon large left color="success">mdi-party-popper</v-icon>
                        User is not in any SharePoint groups.
                    </v-sheet>
                </div>
                <div v-else-if="!loading && spoUser.groups && spoUser.groups.length" class="mt-5">
                    <v-data-table
                        v-model="selectedGroups"
                        :headers="headers"
                        :items="spoUser.groups"
                        :show-select="editMode"
                        class="elevation-1"
                        item-key="Id"
                        @toggle-select-all="() => {selectedGroups = spoUser.groups}"
                    >
                        <template v-slot:item.Title="{ item }">
                            {{ item.Title }}
                        </template>
                        <template v-slot:item.OwnerTitle="{ item }">
                            {{ item.OwnerTitle }}
                        </template>
                        <template v-slot:item.Description="{ item }">
                            {{ item.Description }}
                        </template>
                    </v-data-table>
                </div>
            </div>
            
            <ConfirmDialog
                ref="confirmDlg"
                title="Are you sure you want to remove the user from the group(s)?"
                confirm-button-text="Confirm"
                confirm-button-icon="mdi-check"
                @confirm="submitEditGroups"
            />
            <AddUserToSpoGroupDialog 
                ref="addUserToSpoGroupDlg"
            />
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api';
import ConfirmDialog from '@/components/common/ConfirmDialog';
import SearchPeople from '@/components/population/SearchPeople';
import AddUserToSpoGroupDialog from '@/components/ops/spo/AddUserToGroupDialog';
export default {
    name: 'SpoUser',
    components: {
        SearchPeople,
        ConfirmDialog,
        AddUserToSpoGroupDialog
    },
    setup(props, { root }) {

        const store = root.$store;
        const foundEmployee = ref(null);
        const selectedGroups = ref([]);
        const loading = ref(false);
        const spoUser = computed(() => store.getters['ops/getSpoUser']);

        const editMode = ref(false);

        const headers = [{
            text: 'Name',
            key: 'name',
            value: 'Title',
        }, {
            text: 'Owner',
            key: 'owner',
            value: 'OwnerTitle'
        }, {
            text: 'Description',
            key: 'description',
            value: 'Description'
        }];

        const reset = () => {
            editMode.value = false;
            selectedGroups.value = [];
            loading.value = false;
        }

        const submitEditGroups = async () => {
            loading.value = true;
            let res = await store.dispatch('ops/removeUserFromSpoGroups', {
                user: foundEmployee.value,
                groups: selectedGroups.value
            });

            if (res.data.success) {
                await store.dispatch('ops/loadSpoUser', {user: foundEmployee.value.cu_uni});
            }

            reset();

            loading.value = false;

        }

        const getSharePointGroups = async () => {
            root.$refs.addUserToSpoGroupDlg.dialog = true;
        }

        watch(foundEmployee, async (val) => {
            if (val) {
                loading.value = true;
                await store.dispatch('ops/loadSpoUser', {user: val.cu_uni});
                loading.value = false;
            }
        });

        onMounted(async () => {
            await store.dispatch('ops/resetSpoUser');
        });

        return {
            spoUser,
            foundEmployee,
            loading,
            headers,
            selectedGroups,
            editMode,
            submitEditGroups,
            getSharePointGroups
        }
    },
};
</script>