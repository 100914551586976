<template>
    
    <v-autocomplete
        v-model="selectedGroup"
        :items="sharePointGroups"
        density="compact"
        :loading="isLoading"
        cache-items
        label="SharePoint Groups"
        item-text="name"
        return-object
        :hide-details="hideDetails"
        :dense="dense"
        :rounded="rounded"
        :outlined="outlined"
        @input="handleInput($event)"
    >
    </v-autocomplete>
            
</template>
<script>
import { computed, ref } from '@vue/composition-api';

export default {
    name: "SearchSharePointGroup",
    components: {
    },
    props: {
        value: {
            type: Object ,
            default() {
                return {}
            }
        },
        rounded: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: true },
        minChar: { type: Number, default: 2 },
        multiple: { type: Boolean, default: false },
    },
    setup(props, { root, emit }) {

        const store = root.$store;
        const selectedGroup = ref(null);
        const isLoading = ref(false);
        const sharePointGroups = computed(() => store.getters['ops/getSharePointGroups'])

        if (props.multiple) {
            console.log(props.multiple);
            props.value = [];
        }
        // onMounted(() => {
        //     if (props.value && props.value.cu_uni) {
        //         items.value = [props.value];
        //         model.value = items.value[0];
        //     }
        // });

        const handleInput = (e) => {
            if (!e) return;
            
            
            if (props.clearOnInput) {
                root.$nextTick(() => {
                    selectedGroup.value = null
                });
            }
            emit('input', e);
        };

        return {
            handleInput,
            isLoading,
            sharePointGroups,
            selectedGroup,
        }
    },
};
</script>